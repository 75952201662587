import React from 'react';
import CategoryGallery from '@mshops-components-library/categories-gallery';
import { useRenderContext } from '../../pages/home/context';

const Wrapper = (props) => {
  const { device, isEshops, theme, lowEnd, features } = useRenderContext();
  const isNewLayout = features?.includes('image-uploader-v2');
  let isIOS17 = false;

  if (typeof window !== 'undefined' && window.navigator) {
    const { userAgent } = window.navigator;
    const ios17Regex = /iPhone OS 17|iPad OS 17/;

    isIOS17 = ios17Regex.test(userAgent);
  }

  return (
    <CategoryGallery
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      isNewLayout={isNewLayout}
      isIOS17={isIOS17}
      {...props}
    />
  );
};

export default Wrapper;
